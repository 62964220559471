import { DashboardOutlined, UserAddOutlined, BellOutlined,FileTextOutlined, UserSwitchOutlined, MessageOutlined, DiffOutlined, PieChartOutlined, RiseOutlined, FallOutlined, FileSearchOutlined, TagsOutlined, UserOutlined, GlobalOutlined, TranslationOutlined } from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

const dashBoardNavTree = [{
  key: 'home',
  path: `${APP_PREFIX_PATH}/home`,
  title: 'home',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: []
}]

const category = [{
  key: 'category',
  path: `${APP_PREFIX_PATH}/category`,
  title: 'category',
  icon: TagsOutlined,
  breadcrumb: false,
  submenu: []
}]

const users = [{
  key: 'users',
  path: `${APP_PREFIX_PATH}/users`,
  title: 'users',
  icon: UserAddOutlined,
  breadcrumb: false,
  submenu: []
}]

const orders = [{
  key: 'orders',
  path: `${APP_PREFIX_PATH}/orders`,
  title: 'orders',
  icon: FileTextOutlined,
  breadcrumb: false,
  submenu: []
}]

const adverts = [{
  key: 'adverts',
  path: `${APP_PREFIX_PATH}/adverts`,
  title: 'adverts',
  icon: FileSearchOutlined,
  breadcrumb: false,
  submenu: []
}]

const customers = [{
  key: 'customers',
  path: `${APP_PREFIX_PATH}/customers`,
  title: 'customers',
  icon: UserOutlined,
  breadcrumb: false,
  submenu: []
}]

const workers = [{
  key: 'workers',
  path: `${APP_PREFIX_PATH}/workers`,
  title: 'workers',
  icon: UserOutlined,
  breadcrumb: false,
  submenu: []
}]

const customerToSeller = [{
  key: 'customerToSeller',
  path: `${APP_PREFIX_PATH}/customerToSeller`,
  title: 'customerToSeller',
  icon: UserSwitchOutlined,
  breadcrumb: false,
  submenu: []
}]

const payments = [{
  key: 'payments',
  path: `${APP_PREFIX_PATH}/payments`,
  title: 'payments',
  icon: RiseOutlined,
  breadcrumb: false,
  submenu: []
}]

const withdraws = [{
  key: 'withdraws',
  path: `${APP_PREFIX_PATH}/withdraws`,
  title: 'withdraws',
  icon: FallOutlined,
  breadcrumb: false,
  submenu: []
}]

const serviceReports = [{
  key: 'serviceReports',
  path: `${APP_PREFIX_PATH}/serviceReports`,
  title: 'serviceReports',
  icon: PieChartOutlined,
  breadcrumb: false,
  submenu: []
}]

const language = [{
  key: 'language',
  path: `${APP_PREFIX_PATH}/language`,
  title: 'language',
  icon: GlobalOutlined,
  breadcrumb: false,
  submenu: []
}]

const translate = [{
  key: 'translate',
  path: `${APP_PREFIX_PATH}/translate`,
  title: 'translate',
  icon: TranslationOutlined,
  breadcrumb: false,
  submenu: []
}]

const chats = [{
  key: 'chats',
  path: `${APP_PREFIX_PATH}/chats`,
  title: 'chats',
  icon: MessageOutlined,
  breadcrumb: false,
  submenu: []
}]

const sendNotification= [{
  key: 'sendNotification',
  path: `${APP_PREFIX_PATH}/sendNotification`,
  title: 'sendNotification',
  icon: BellOutlined,
  breadcrumb: false,
  submenu: []
}]

const sendMessage= [{
  key: 'sendMessage',
  path: `${APP_PREFIX_PATH}/sendMessage`,
  title: 'sendMessage',
  icon: MessageOutlined,
  breadcrumb: false,
  submenu: []
}]

const adminChats = [{
  key: 'adminChats',
  path: `${APP_PREFIX_PATH}/adminChats`,
  title: 'adminChats',
  icon: MessageOutlined,
  breadcrumb: false,
  submenu: []
}]

const contact = [{
  key: 'contact',
  path: `${APP_PREFIX_PATH}/contact`,
  title: 'contact',
  icon: DiffOutlined,
  breadcrumb: false,
  submenu: []
}]

const references = [{
  key: 'references',
  path: `${APP_PREFIX_PATH}/references`,
  title: 'references',
  icon: DiffOutlined,
  breadcrumb: false,
  submenu: []
}]

let navigationConfig = [
  ...dashBoardNavTree
]

// navigationConfig.push(...category);
navigationConfig.push(...orders);
navigationConfig.push(...adverts);
navigationConfig.push(...workers);
navigationConfig.push(...customers);
navigationConfig.push(...customerToSeller);
navigationConfig.push(...payments);
navigationConfig.push(...withdraws);
navigationConfig.push(...users);
navigationConfig.push(...sendNotification);
navigationConfig.push(...sendMessage);
navigationConfig.push(...serviceReports);
navigationConfig.push(...chats);
navigationConfig.push(...adminChats);
navigationConfig.push(...contact);
navigationConfig.push(...references);
navigationConfig.push(...language);
navigationConfig.push(...translate);
// navigationConfig.push(...settings);

export default navigationConfig;