import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />
        <Route path={`${APP_PREFIX_PATH}/users`} component={lazy(() => import(`./users`))} />
        <Route path={`${APP_PREFIX_PATH}/workers`} component={lazy(() => import(`./workers`))} />
        <Route path={`${APP_PREFIX_PATH}/customers`} component={lazy(() => import(`./customers`))} />
        <Route path={`${APP_PREFIX_PATH}/contact`} component={lazy(() => import(`./contact`))} />
        <Route path={`${APP_PREFIX_PATH}/customerToSeller`} component={lazy(() => import(`./customer-to-seller`))} />
        <Route path={`${APP_PREFIX_PATH}/references`} component={lazy(() => import(`./references`))} />
        <Route path={`${APP_PREFIX_PATH}/category`} component={lazy(() => import(`./category`))} />
        <Route path={`${APP_PREFIX_PATH}/language`} component={lazy(() => import(`./language`))} />
        <Route path={`${APP_PREFIX_PATH}/customer/:id`} component={lazy(() => import(`./customers/detail`))} />
        <Route path={`${APP_PREFIX_PATH}/chats`} component={lazy(() => import(`./chats`))} />
        <Route path={`${APP_PREFIX_PATH}/chat/:id`} component={lazy(() => import(`./chats/detail`))} />
        <Route path={`${APP_PREFIX_PATH}/sendNotification`} component={lazy(() => import(`./sendNotification`))} />
        <Route path={`${APP_PREFIX_PATH}/sendMessage`} component={lazy(() => import(`./sendMessage`))} />
        <Route path={`${APP_PREFIX_PATH}/adminChats`} component={lazy(() => import(`./adminChats`))} />
        <Route path={`${APP_PREFIX_PATH}/adminChat/:id`} component={lazy(() => import(`./adminChats/detail`))} />
        <Route path={`${APP_PREFIX_PATH}/worker/:id`} component={lazy(() => import(`./workers/detail`))} />
        <Route path={`${APP_PREFIX_PATH}/translate`} component={lazy(() => import(`./translate`))} />
        <Route path={`${APP_PREFIX_PATH}/settings`} component={lazy(() => import(`./settings`))} />
        <Route path={`${APP_PREFIX_PATH}/orders`} component={lazy(() => import(`./orders`))} />
        <Route path={`${APP_PREFIX_PATH}/order/:id`} component={lazy(() => import(`./orders/detail`))} />
        <Route path={`${APP_PREFIX_PATH}/adverts`} component={lazy(() => import(`./adverts`))} />
        <Route path={`${APP_PREFIX_PATH}/advert/:id`} component={lazy(() => import(`./adverts/detail`))} />
        <Route path={`${APP_PREFIX_PATH}/payments`} component={lazy(() => import(`./payments`))} />
        <Route path={`${APP_PREFIX_PATH}/withdraws`} component={lazy(() => import(`./withdraws`))} />
        <Route path={`${APP_PREFIX_PATH}/serviceReports`} component={lazy(() => import(`./serviceReports`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);