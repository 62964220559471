import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AUTH_TOKEN } from 'redux/constants/Auth'
import i18next from "i18next";
import ExampleService from "services/ExampleService";

const LoadApp = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    let languageId = localStorage.getItem("languageId");
    let languageShortName = localStorage.getItem("languageShortName");
    if (!languageId) {
      languageId = 1;
      localStorage.setItem("languageId", 1);
      localStorage.setItem("languageShortName", "tr");
      window.location.reload();
    }

    ExampleService.setPost("dataTranslate/dropdown/" + languageId, {}).then(resp => {
      var list = resp.data.map((item) => {
        return (`"${item.keyword}" : "${item.value}"`);
      });

      var translateData = JSON.parse('{"' + languageShortName + '"' + ': {"translation": {' + list.join(",") + '}}} ');
      i18next.init({
        lng: languageShortName,
        debug: false,
        resources: translateData,
        keySeparator: true
      });
    }).catch(e => {
      if (e) console.log(e);
    })

    const jwtToken = localStorage.getItem(AUTH_TOKEN)
    if (jwtToken) {
      ExampleService.getPost("auth/userinfo", null).then(resp => {
        localStorage.setItem("userinfo", JSON.stringify(resp.data?.user));
      }).catch(e => {
        if (e) console.log(e);
      })
    }
    else{
      if(window.location?.pathname != "/auth/login"){
        window.location.href = "/auth/login";
      }
    }
  }, [])

  return null;
}

export default LoadApp;