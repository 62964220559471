import { all, takeEvery, put, fork } from 'redux-saga/effects';
import {
	AUTH_TOKEN,
	SIGNIN,
	SIGNOUT
} from '../constants/Auth';
import {
	showAuthMessage,
	signIn,
	signOutSuccess,
} from "../actions/Auth";

export function* signInWithUser() {
	yield takeEvery(SIGNIN, function* ({ payload }) {
		try {
			// localStorage.setItem(AUTH_TOKEN, payload.token);
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signOut() {
	yield takeEvery(SIGNOUT, function* () {
		try {
			localStorage.removeItem(AUTH_TOKEN);
			localStorage.removeItem("userinfo");
			localStorage.removeItem("loginRefresh");
			window.location.href = "/auth/login";
			yield put(signOutSuccess(null));
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(signInWithUser),
		fork(signOut)
	]);
}
